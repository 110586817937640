<template>
  <WrapperSkeleton>
    <template v-slot:skeleton>
      <div class="skeleton-form-control">
        <SkeletonElement skeletonType="label" />
        <SkeletonElement skeletonType="input" />
      </div>
    </template>
  </WrapperSkeleton>
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
  components: {
    WrapperSkeleton: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "WrapperSkeleton" */ '@/modules/cms/skeletons/WrapperSkeleton.vue'
      )
    ),
    SkeletonElement: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "SkeletonElement" */ '@/modules/cms/skeletons/SkeletonElement.vue'
      )
    ),
  },
};
</script>
